<template>
  <div class="UserSelect">
    <button
      v-for="(user, index) in users"
      class="UserSelect__user"
      :class="UserSelectItemClass(index)"
      :key="user.id"
      @mousedown.stop="$emit('onUserSelect', index)"
    >
      <div class="UserSelect__userWrap">
        <img class="UserSelect__userAva" :src="user.logo" />
        <div class="UserSelect__userInfo">
          <span class="UserSelect__userName">{{ getUserName(user) }}</span>
          <span v-if="user.first_name && user.last_name" class="UserSelect__userEmail">
            {{ user.email }}
          </span>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true
    },

    selectedUserIndex: {
      type: Number,
      default: 0
    },
  },

  watch: {
    selectedUserIndex() {
      this.scrollToSelectedUser();
    },
  },

  methods: {
    getUserName({first_name, last_name, email}) {
      if (first_name && last_name) {
        const clearName = first_name.replace(/\w/, first_name[0].toUpperCase());
        const clearSurname = last_name[0].toUpperCase();

        return `${clearName} ${clearSurname}.`;
      }

      return email;
    },

    scrollToSelectedUser() {
      const users = this.$el.querySelectorAll('.UserSelect__user');
      const selectedUser = users[this.selectedUserIndex];

      selectedUser.scrollIntoView({block: 'nearest'});
    },

    UserSelectItemClass(index) {
      return {
        'UserSelect__user': true,
        'UserSelect__user--selected': this.selectedUserIndex === index
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.UserSelect {
  $border-radius: 4px;

  display: grid;
  grid-auto-rows: 36px;
  border-radius: $border-radius;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @include dark-theme {
    background-color: $darkThemeBgTwo;
  }

  // Пользователь
  &__user {
    border: none;
    transition: all 0.2s;

    &:active, &:focus, &:hover, &--selected {
      cursor: pointer;
      outline: none;
      background-color: $lightThemeBackground2;

      @include dark-theme {
        background-color: $darkThemeBgOne;
      }
    }
  }

  &__userAva {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &__userWrap {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0 8px;
    padding: 0 6px;
    text-align: left;
    border-radius: $border-radius;
    align-items: center;
  }

  &__userInfo {
    display: grid;
  }

  &__userName {
    color: #000000;
    font-size: 12px;
    font-weight: 300;

    @include dark-theme {
      color: $darkThemeTextColor;
    }
  }

  &__userEmail {
    color: #ABABAB;
    font-size: 10px;
    font-weight: 300;

    @include dark-theme {
      color: #747474;
    }
  }
}
</style>
